/**
 * Load a script dynamically, defaults to asynchronous loading.
 * 
 * @param string src    The URL of the script to load
 * @param bool async   Set to false if you want the script loaded synchronously (blocking render)
 */
function loadScript(src, async) {
    if (async == null) {
        async = true;
    }
    var scriptTag = document.createElement('script');
    scriptTag.type = 'text/javascript';
    scriptTag.src = src;
    scriptTag.async = async;
    var existingScriptTag = document.getElementsByTagName('script')[0];
    existingScriptTag.parentNode.insertBefore(scriptTag, existingScriptTag);
}

// If the browser does not support HTML5, load HTML5Shiv
if (!Modernizr.canvas) {
    loadScript('https://cdn.jsdelivr.net/npm/html5shiv@3/dist/html5shiv-printshiv.min.js', false);
}

// If the browser cannot handle media queries, load respond.js
if (!Modernizr.mediaqueries) {
    loadScript('https://cdn.jsdelivr.net/npm/respond.js@1.4.2/dest/respond.min.js');
}

// If the browser cannot handle img srcset or sizes attributes, load 
if (!Modernizr.srcset || !Modernizr.sizes) {
    loadScript('https://cdn.jsdelivr.net/combine/npm/respimage@1,npm/respimage@1/plugins/intrinsic-dimension/ri.intrinsic.min.js,npm/respimage@1/plugins/mutation/ri.mutation.min.js');
}